import React, { Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Styled } from "theme-ui";

function Footer() {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          social {
            name
            url
          }
        }
      }
    }
  `);

  const {
    site: {
      siteMetadata: { social },
    },
  } = data;

  return (
    <footer>
      © {new Date().getFullYear()}, Powered by
      {` `}
      <Styled.a href="https://www.gatsbyjs.org">Gatsby</Styled.a>
      {` `}&bull;{` `}
      {social.map((platform, i, arr) => (
        <Fragment key={platform.url}>
          <Styled.a
            href={platform.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {platform.name}
          </Styled.a>
          {arr.length - 1 !== i && (
            <Fragment>
              {` `}&bull;{` `}
            </Fragment>
          )}
        </Fragment>
      ))}
    </footer>
  );
}

export default Footer;
