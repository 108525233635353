import { Link, useStaticQuery } from "gatsby";
import Image from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import LightSwitch from "./LightSwitch";
import { css, Styled } from "theme-ui";

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(query);
  const {
    site: {
      siteMetadata: { author },
    },
    avatar,
  } = data;

  return (
    <>
      <header
        css={css({
          py: 4,
        })}
      >
        <div
          css={css({
            position: "relative",
            display: "flex",
            justifyContent: "center",
            mb: 1,
          })}
        >
          <Link to="/" title="Home">
            <Image
              fixed={avatar.childImageSharp.fixed}
              alt={author}
              css={css({
                mb: 0,
                width: [
                  "50px !important",
                  "75px !important",
                  "100px !Important",
                ],
                height: [
                  "50px !important",
                  "75px !important",
                  "100px !Important",
                ],
                borderRadius: 99999,
              })}
            />
            <span
              css={css({
                position: "absolute",
                width: 1,
                height: 1,
                overflow: "hidden",
                top: -9999,
              })}
            >
              Home
            </span>
          </Link>
        </div>
        <div
          css={css({
            display: "flex",
            justifyContent: "center",
            fontFamily: "heading",
            fontWeight: "bold",
          })}
        >
          <Styled.a
            as={Link}
            css={css({
              textDecoration: `none`,
              p: 2,
            })}
            to="/"
          >
            Blog
          </Styled.a>
          <Styled.a
            as={Link}
            to="/notes"
            css={css({
              textDecoration: "none",
              p: 2,
            })}
          >
            Notes
          </Styled.a>
          <Styled.a
            as={Link}
            to="/about"
            css={css({
              textDecoration: "none",
              p: 2,
            })}
          >
            About
          </Styled.a>
        </div>
      </header>

      <LightSwitch />
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

const query = graphql`
  query headerQuery {
    site {
      siteMetadata {
        author
      }
    }
    avatar: file(absolutePath: { regex: "/avatar.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default Header;
