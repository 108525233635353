import React from "react";
import ReactSwitch from "react-switch";
import { css, useColorMode } from "theme-ui";

import moon from "gatsby-theme-blog/assets/moon.png";
import sun from "gatsby-theme-blog/assets/sun.png";

const checkedIcon = (
  <img
    alt="moon indicating dark mode"
    src={moon}
    width="16"
    height="16"
    role="presentation"
    css={{
      pointerEvents: `none`,
      margin: 4,
    }}
  />
);

const uncheckedIcon = (
  <img
    alt="sun indicating light mode"
    src={sun}
    width="16"
    height="16"
    role="presentation"
    css={{
      pointerEvents: `none`,
      margin: 4,
    }}
  />
);

export const LightSwitch = props => {
  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`;
  const toggleColorMode = e => {
    setColorMode(isDark ? `light` : `dark`);
  };

  return (
    <ReactSwitch
      checkedIcon={checkedIcon}
      uncheckedIcon={uncheckedIcon}
      checked={isDark}
      onChange={toggleColorMode}
      aria-label="Toggle dark mode"
      css={css({
        position: "absolute !important",
        top: 30,
        right: 30,
        bg: `black`,
      })}
      {...props}
    />
  );
};

LightSwitch.defaultProps = {
  height: 24,
  width: 48,
  handleDiameter: 24,
  offColor: `#000`,
  onColor: `#000`,
  boxShadow: `inset 0 0 0 1px #000`,
};

export default LightSwitch;
